// import { DateTime } from "luxon";
import { CloseButton, Modal } from "react-bootstrap";
import PromoImage from '../../assets/images/2024JulyCrashPromo.png';


// const USER_TIMEZONE = Intl.DateTimeFormat().resolvedOptions().timeZone;
// const START_TIME = "2024-05-09T17:00:00.000Z";
// const END_TIME = "2024-05-16T23:00:00.000Z";

export function AnnouncementModal({ styleCss, ...props }) {
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      contentClassName={styleCss + `fixthislater light-color`}
      centered
    >
      <Modal.Body className={"p-0"}>
        <div className="card card-user shadow-lg">
          <div className="pt-4 px-4">
            <div className="d-flex justify-content-between">
              <h5 className="mb-0">WHAT'S NEW</h5>
              <CloseButton onClick={props.onHide} />
            </div>
            <small>July 13th, 2024</small>
          </div>
          <div className={"card-body px-4" + (styleCss === 'dark' ? ' pb-0' : '')} style={{ maxHeight: '500px', overflowY: 'scroll' }}>
            <div className="d-flex">
            <img src={PromoImage} alt="banner" className="img-fluid mb-1 rounded-3 mx-auto" style={{maxHeight: '250px'}}></img>
            </div>
            {/* <div className="rounded-3" style={{ width: '100%', overflow: 'hidden', maxHeight: '240px', position: 'relative' }}>
              <video className="rounded-3" style={{ width: '100%', height: '100%', transform: 'translate(0, -10%)' }} autoPlay playsInline>
                <source src="https://i.imgur.com/yANeMmt.mp4" type="video/mp4" />
              </video>
            </div> */}
            {/* <div className="d-flex justify-content-between mt-3 text-primary">
              <h6>SHOP&nbsp;IS&nbsp;LIVE&nbsp;</h6>
              <hr className="w-100 mt-2" />
            </div>

            <ul>
              <li>Spend your points @ <a href="https://shop.degencoinflip.com" target="_blank" rel="noopener noreferrer"><b>shop.degencoinflip.com</b></a> and claim RFFs and other rewards.</li>
            </ul> */}
            <div className="d-flex justify-content-between mt-3 text-success">
              <h6>Degen&nbsp;Pump&nbsp;Fun's&nbsp;First&nbsp;Launch</h6>
              <hr className="w-100 mt-2" />
            </div>

            <ul>
              <li>
                 Only available at <a href="https://crash.degencoinflip.com" target="_blank" rel="noopener noreferrer"><b>crash.degencoinflip.com</b></a> 
                 <br/>
                 &emsp;⦿ Pump fun with a twist
                 <br/>
                 &emsp;⦿ Everyone buys at the same price 
                 <br/>
                 &emsp;⦿ You can only sell your full position size
                 <br/>
                </li>

                {/* <li>Access:</li>
                <ul>
                  <li>App is in beta, admitting 1,000 users at a time</li>
                  <li>Check your waitlist position on the site</li>
                  <li>Opens to public after waitlist</li>
                  <li>Eligible waitlist members get risk-free plays, sign in to see</li>
                </ul> */}
                <li>How to Use Risk-Free Plays: </li>
                <ul>
                1. Activate for the launch
                <br/>2. If rugged, SOL refunded within 1 minute
                <br/>3. If you win, keep profits and use up risk-free play
                </ul>
                 {/* <br/>Start: {DateTime.fromISO(START_TIME, { zone: 'UTC' }).setZone(USER_TIMEZONE).toLocaleString(DateTime.DATETIME_FULL)}
                 <br/>End: {DateTime.fromISO(END_TIME, { zone: 'UTC' }).setZone(USER_TIMEZONE).toLocaleString(DateTime.DATETIME_FULL)}
                <br/>@ <a href="https://shop.degencoinflip.com" rel="noreferrer" target="_blank">shop.degencoinflip.com</a> */}
            </ul>

            <div className="d-flex justify-content-between mt-3 text-warning">
              <h6>POINTS&nbsp;SYSTEM&nbsp;</h6>
              <hr className="w-100 mt-2" />
            </div>

            <ul>
              <li>Accumulate points as you play! Everlasting and ready for future DCF ecosystem surprises.</li>
            </ul>

            <div className="d-flex justify-content-between mt-3 text-danger">
              <h6>FUTURE&nbsp;RELEASES&nbsp;</h6>
              <hr className="w-100 mt-2" />
            </div>

            <ul>
              <li>We have new updates coming for DCF this year 2024. Stick around and find out. <a href="https://twitter.com/degencoinflip" rel="noreferrer" target="_blank">Twitter</a> & <a href="https://discord.gg/degencoinflip" rel="noreferrer" target="_blank">Discord</a>.</li>
            </ul>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
