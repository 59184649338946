import { useWallet } from "@solana/wallet-adapter-react";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
// import { isPromoActive } from "../utils/helpers";
import { loadState, saveState } from "../utils/localStorage";
import { AnnouncementModal } from "./modals/AnnouncementModal";

const now = DateTime.utc();
const MODAL_KEY = `${now.year}_CRASH_LAUNCH`;

export const AnnouncementsWidget = ({ style }) => {
    const wallet = useWallet();
    const [showJackpotModal, setShowJackpotModal] = useState(false);
    const handleJackpotModalOpen = () => setShowJackpotModal(true);
    const handleJackpotModalClose = () => setShowJackpotModal(false);

    useEffect(() => {
        if (!loadState(MODAL_KEY) && wallet.connected) {
            handleJackpotModalOpen();
            saveState(true, MODAL_KEY);
        }
        // eslint-disable-next-line
    }, [wallet]);

    return (
        <>
            {
                showJackpotModal &&
                <AnnouncementModal
                    show={showJackpotModal}
                    styleCss={style}
                    onHide={handleJackpotModalClose}
                />
            }
        </>
    );
};
