import { isMobile } from 'react-device-detect';
import { useWallet } from "@solana/wallet-adapter-react";
import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { AuthorizationContext } from "../../contexts/auth.context";
import { CommunityContext } from "../../contexts/community.context";
import { RecentCoinFlipsContext } from "../../contexts/recent-coin-flips.context";
import { StyleThemeContext } from "../../contexts/style-theme.context";
import { GameModeContext } from "../../contexts/game-mode.context";
import CoinFlipWizardHome from "./CoinFlipWizardHome";
import CoinFlipWizardStepOne from "./CoinFlipWizardStepOne";
import CoinFlipWizardStepTwo from "./CoinFlipWizardStepTwo";
import { confetti } from '../../utils/confetti';
import { ActivePromoIdKey, CoinFlipStatus, RiskFreePlaysStatus, WAGMI } from '../../utils/constants';
import CoinFlipWizardStepThree from './CoinFlipWizardStepThree';
import { getCoinFlip } from '../../api/degen.service';
import { depositSol } from '../../services/solana-deposit.service';
import { initCoinFlip, processCoinFlip, processCoinFlipWithMemo } from '../../api/coin-flip.service';
import { getDegenerateAccountBalance, rewardExists } from '../../api-smart-contracts/dcf';
import { AudioContext } from '../../contexts/audio.context';
import { ToastRugged } from '../toasts/RuggedToast';
import { ToastCongrats } from '../toasts/CongratsToast';
import CoinFlipWizardStepContinue from './CoinFlipWizardStepContinue';
import CoinFlipWizardStepContinueOld from './CoinFlipWizardContinueOld';
import { ShareCardModal } from '../modals/ShareCardModal';
import { ToastKickback } from '../toasts/KickbackToast';
import { getUIFX } from './streak.ui';
import { UserContext } from '../../contexts/user.context';
import { RiskFreePlaysContext } from '../../contexts/risk-free-plays.context';
import { getPromoFlip } from '../../api/atlantis.service';
import canvasConfetti from 'canvas-confetti';
import { ToastRiskFreeFlip } from '../toasts/RiskFreeFlipToast';
import { getRiskFreePlayByReference } from '../../api/aegis.service';
import { isPromoFlagEnabled } from '../../utils/helpers';
import { loadState } from '../../utils/localStorage';
// import { ToastFragments } from '../toasts/ToastFragments';
import { ToastNewRaffles } from '../toasts/ToastNewRaffles';
import { TransactionPriorityModal } from '../modals/PriorityFeeModal';
import { CoinFlipFailedToast } from '../toasts/CoinFlipFailedToast';
// import SideBetsContainer from '../side-bets/SideBetsContainer';

const CoinFlipWizardStatuses = {
  WAITING_FOR_DEPOSIT: "WAITING_FOR_DEPOSIT.",
  FLIPPING: "FLIPPING",
  FLIPPING_OLD: "FLIPPING_OLD"
};

const IGNOREABLE_AMOUNT = 0.01;

const confettiAnimation: any = confetti;

const CoinFlipWizardContainer = () => {
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const wallet = useWallet();

  const { auth, signIn, signInViaLedger, isExpired } = useContext(AuthorizationContext);
  const { restricted, connect } = useContext(UserContext);
  const { riskFreePlays, getRiskFreePlays } = useContext(RiskFreePlaysContext);
  const { community } = useContext(CommunityContext);
  const { recentCoinFlips } = useContext(RecentCoinFlipsContext);
  const { style } = useContext(StyleThemeContext);
  const { whaleMode, proMode } = useContext(GameModeContext);
  const { play, playBg } = useContext(AudioContext);

  const [coinFlip, setCoinFlip] = useState<any>(null);
  const [price, setPrice] = useState(proMode ? 0 : 0.05)
  const [priorityFee, setPriorityFee] = useState(0);
  const [side, setSide] = useState("H");
  const [superStreak, setSuperStreak] = useState(false);

  const [state, setState] = useState<any>({
    page: null,
    currentWinStreak: 0
  });

  const [isInit, setIsInit] = useState(true);
  const [skipReward, setSkipReward] = useState(false);
  const [ruggedToastShow, setRuggedToastShow] = useState<boolean>(false);
  const [congratsToastShow, setCongratsToastShow] = useState<boolean>(false);
  const [shareToastShow, setShareToastShow] = useState<boolean>(false);
  const [riskFreeFlipToastShow, setRiskFreeFlipToastShow] = useState<boolean>(false);
  const [kickbackToastShow, setKickbackToastShow] = useState<boolean>(false);
  const [fragmentsToastShow, setFragmentsToastShow] = useState<boolean>(false);
  const [riskFreeFlip, setRiskFreeFlip] = useState<any>(null);

  const [fragments, setFragments] = useState(null);

  const [loading, setLoading] = useState<any>(false);
  const enableLoading = () => setLoading(true);
  const disableLoading = () => setLoading(false);

  const [showShareCardModal, setShareCardModal] = useState(false);
  const handleShareCardModalOpen = () => setShareCardModal(true);
  const handleShareCardModalClose = () => setShareCardModal(false);

  const [showTransactionPriorityModal, setShowTransactionPriorityModal] = useState(false);
  const handleTransactionPriorityModalOpen = () => setShowTransactionPriorityModal(true);
  const handleTransactionPriorityModalClose = () => setShowTransactionPriorityModal(false);

  const [showCoinFlipErrorToast, setShowCoinFlipErrorToast] = useState(false);
  const handleCoinFlipToastOpen = () => setShowCoinFlipErrorToast(true);
  const handleCoinFlipToastClose = () => setShowCoinFlipErrorToast(false);

  const canSkip = () => {
    const queryParams = new URLSearchParams(location.search);
    const mode = queryParams.get('gl');
    if (mode !== 'hf') return false;
    enableLoading();
    setState({
      ...state,
      page: 'one'
    });
    return true;
  }

  useEffect(() => {
    if (
      wallet.connected &&
      !isExpired() &&
      state.page === null
    ) {
      const skip = canSkip();
      if (skip) return;
    }

    if (
      wallet.connected &&
      state.page === 'one' &&
      !state.currentWinStreak &&
      isInit
    ) {
      setIsInit(false);
      checkOnInit();
    }
    // eslint-disable-next-line
  }, [state]);

  const checkOnInit = async () => {
    enableLoading();
    const rewardable = await checkForReward();
    if (!rewardable) await checkForDeposit();
    disableLoading();
  }

  useEffect(() => {
    if (coinFlip?.status === CoinFlipStatus.PROCESSING) {
      setState({
        ...state,
        currentWinStreak: coinFlip?.winStreak,
        streak: coinFlip?.won ? coinFlip?.winStreak : coinFlip?.loseStreak,
        page: 'three'
      });
    }
    // eslint-disable-next-line
  }, [coinFlip])

  useEffect(() => {
    async function exec() {
      try {
        const { coinFlip } = state;
        const response = await processCoinFlipWithMemo(coinFlip?.id, coinFlip?.signature, auth?.idToken);
        handleUI(response);
        setCoinFlip(response);
      }
      catch (e) {
        setState({
          ...state,
          page: 'one'
        });
        disableLoading();
      }
    }
    async function execOld() {
      const { coinFlip } = state;
      const response = await processCoinFlip(coinFlip, auth?.idToken);
      handleUI(response);
      setCoinFlip(response);
    }
    if (state?.page === 'two' && state?.status === CoinFlipWizardStatuses.FLIPPING) {
      exec();
    }
    if (state?.page === 'two' && state?.status === CoinFlipWizardStatuses.FLIPPING_OLD) {
      execOld();
    }
    // eslint-disable-next-line
  }, [state])

  const playConfettiAnimation = (defaultTimeout = 1000, min: any = null, max: any = null) => {
    confettiAnimation?.start(defaultTimeout, min, max);
    setTimeout(function () {
      confettiAnimation?.stop()
    }, defaultTimeout);
  };

  const handleUI = (coinFlip: any) => {

    const {
      won,
      winStreak,
      winStreakWhale,
      loseStreakWhale,
      loseStreak
    } = coinFlip;
    const prevWinStreak = state?.currentWinStreak;
    const streak = won ? winStreak : loseStreak;
    // const isSuperStreak = streak >= 7;
    setSuperStreak(won ? streak >= 6 : streak > 6);

    const { audio, confettiLevel } = getUIFX(
      won,
      winStreak,
      loseStreak,
      prevWinStreak,
      winStreakWhale > 0 || loseStreakWhale > 0
    );
    play(audio);

    if (confettiLevel) {
      playConfettiAnimation(...confettiLevel);
    }

    if (won) {
      clearAllToasts();
      setCongratsToastShow(true);
      setTimeout(() => setShareToastShow(true), 2000);
    }
    else {
      clearAllToasts();
      setRuggedToastShow(true);
    }
  };

  const login = async (authViaTx = false) => {
    enableLoading();

    let isRestricted = restricted && community?.slug === WAGMI;
    if (isRestricted) {
      isRestricted = await connect(wallet?.publicKey?.toString());
    }

    if (isRestricted) {
      navigate("/try", { replace: true });
      return;
    }

    startWizard(authViaTx);
  }

  const startWizard = async (authViaTx = false) => {
    enableLoading();

    try {
      if (!authViaTx) {
        await signIn(
          wallet as any,
          params?.referral as string
        );
      } else {
        await signInViaLedger(wallet);
      }
    }
    catch {
      disableLoading();
      return;
    }

    setState({
      ...state,
      page: 'one'
    });

    disableLoading();
  }

  const checkForReward = async () => {
    const awaitingReward = await rewardExists(wallet);
    if (awaitingReward) {
      const coinFlip = await getCoinFlip(auth?.idToken);
      // todo: fix
      const wonCf = { ...coinFlip, won: true };
      handleUI(wonCf);
      setCoinFlip(wonCf);
      setState({
        ...state,
        page: 'three',
        coinFlip: wonCf,
        currentWinStreak: coinFlip?.winStreak,
        streak: coinFlip?.won ? coinFlip?.winStreak : coinFlip?.loseStreak,
      });
      return coinFlip;
    }
  }

  const checkForDeposit = async () => {
    const depositedAmount = await getDegenerateAccountBalance(wallet);
    if (depositedAmount < IGNOREABLE_AMOUNT) {
      return;
    }

    setPrice(depositedAmount);
    // todo: checks for existing processing coin flip
    //       remove for multi coin flipping
    try {
      const coinFlip = await getCoinFlip(auth?.idToken);
      if (coinFlip?.status === CoinFlipStatus.PROCESSING) {
        handleUI(coinFlip);
        setCoinFlip(coinFlip);
        setState({
          ...state,
          page: 'three',
          coinFlip,
          currentWinStreak: coinFlip?.winStreak,
          streak: coinFlip?.won ? coinFlip?.winStreak : coinFlip?.loseStreak,
        });
        return coinFlip;
      }

      if (coinFlip?.status === CoinFlipStatus.STARTED) {
        setPrice(coinFlip?.amount);
        setSide(coinFlip?.side);
        setState({
          ...state,
          page: 'continue',
          coinFlip: {
            ...coinFlip,
            signature: coinFlip?.depositTxn
          }
        });
        return coinFlip;
      }
    }
    catch (e) {
      console.log(e);
    }
  }

  const startFlip = async ({ side, amount }: any) => {
    setState({
      ...state,
      page: 'two',
      status: CoinFlipWizardStatuses.WAITING_FOR_DEPOSIT,
      coinFlip: {
        side,
        amount
      }
    });

    const coinFlip = {
      side,
      amount: amount,
      mode: proMode ? "PRO" : (whaleMode && amount >= 3 ? "WHALE" : "DEFAULT"),
      isMobile
    }

    enableLoading();
    const reward = await checkForReward();
    if (reward) return;

    const deposited = await checkForDeposit();
    if (deposited) return;

    let depositTxn = null;
    const initializedCoinFlip = await initCoinFlip(coinFlip, auth?.idToken);

    const { id } = initializedCoinFlip;

    try {
      depositTxn = await depositSol(wallet, amount, id, side, priorityFee);
    } catch {
      handleCoinFlipToastOpen();
      disableLoading();
      setState({
        ...state,
        page: 'one'
      });
      return;
    }

    setState({
      ...state,
      page: 'two',
      status: CoinFlipWizardStatuses.FLIPPING,
      coinFlip: {
        side,
        amount,
        signature: depositTxn ?? null,
        mode: proMode ? "PRO" : (whaleMode && amount >= 3 ? "WHALE" : "DEFAULT"),
        isMobile,
        id
      }
    });
  };

  const continueFlip = async () => {
    enableLoading();

    setTimeout(() => {
      setState({
        ...state,
        page: 'two',
        status: CoinFlipWizardStatuses.FLIPPING
      });
    }, 4200);

  };

  const continueFlipOld = async ({ side, amount }: any) => {
    enableLoading();

    setTimeout(() => {
      setState({
        ...state,
        page: 'two',
        status: CoinFlipWizardStatuses.FLIPPING_OLD,
        coinFlip: {
          side,
          amount,
          signature: null,
          mode: proMode ? "PRO" : (whaleMode && amount >= 3 ? "WHALE" : "DEFAULT"),
          isMobile
        }
      });
    }, 4200);

  };

  const checkForKickback = async (flipId: string) => {
    const promoId = loadState(ActivePromoIdKey);
    const details = await getPromoFlip(promoId, flipId, auth?.idToken);
    // const flipWhitelistDetails = await getCoinFlipWLReward(flipId);
    // const { kickback, riskFreeFlip } = details;
    const { fragments } = details;
    // if (earnedWlBox && kickback && riskFreeFlip) {
    //   clearAllToasts();
    //   setKickbackToastShow(true);
    //   play(15);

    //   setRiskFreeFlip(riskFreeFlip);
    //   setTimeout(() => {
    //     clearAllToasts();
    //     setRiskFreeFlipToastShow(true);
    //     play(16);
    //   }, 5000);

    //   setTimeout(() => {
    //     clearAllToasts();
    //     setWlToastShow(true);
    //     play(17);
    //   }, 10000);
    // }
    // else
    //  if (kickback && riskFreeFlip) {
    //   clearAllToasts();
    //   setKickbackToastShow(true);
    //   play(15);

    //   setRiskFreeFlip(riskFreeFlip);
    //   setTimeout(() => {
    //     clearAllToasts();
    //     setRiskFreeFlipToastShow(true);
    //     play(16);
    //   }, 5000);
    // }
    // else if (earnedWlBox && riskFreeFlip) {
    //   clearAllToasts();
    //   setRiskFreeFlipToastShow(true);
    //   play(15);

    //   setRiskFreeFlip(riskFreeFlip);
    //   setTimeout(() => {
    //     clearAllToasts();
    //     setWlToastShow(true);
    //     play(16);
    //   }, 5000);
    // }
    // else if (earnedWlBox && kickback) {
    //   clearAllToasts();
    //   setKickbackToastShow(true);
    //   play(15);

    //   setRiskFreeFlip(riskFreeFlip);
    //   setTimeout(() => {
    //     clearAllToasts();
    //     setRiskFreeFlipToastShow(true);
    //     play(16);
    //   }, 5000);
    // }
    // else if (kickback) {
    //   clearAllToasts();
    //   setKickbackToastShow(true);
    //   play(15);
    // }
    // else 
    if (fragments) {
      setFragments(fragments);
      clearAllToasts();
      setFragmentsToastShow(true);
      setTimeout(() => {
        canvasConfetti({
          particleCount: Math.floor(Math.random() * 20),
          spread: Math.floor(Math.random() * 20),
          colors: [Math.random() > 0.5 ? '#dc3545' : '#28a745'],
          origin: {
            x: 0, y: 1
          },
          angle: Math.floor(Math.random() * 45)
        });
      }, 300);
      if (state.streak <= 3) {
        playBg(15);
      }
      else if (state.streak <= 5) {
        playBg(16);
      }
      else if (state.streak <= 7) {
        playBg(17);
      }
      else if (state.streak === 10) {
        playBg(14);
      }
    }
    // else if (earnedWlBox) {
    //   clearAllToasts();
    //   setWlToastShow(true);
    //   play(15);
    // }
  }

  const clearAllToasts = () => {
    setCongratsToastShow(false);
    setRuggedToastShow(false);
    setRiskFreeFlipToastShow(false);
    setFragmentsToastShow(false);
    // setKickbackToastShow(false);
    // setWlToastShow(false);
  }

  const handleRiskFreeFlipUI = async (flipId: string) => {
    const hasActivated = riskFreePlays?.filter((rff: any) => rff.status === RiskFreePlaysStatus.ACTIVATED)?.length > 0;
    if (!hasActivated) {
      return;
    }

    const [riskFreeFlip] = await getRiskFreePlayByReference(flipId, auth?.idToken);
    if (riskFreeFlip) {
      setRiskFreeFlip(riskFreeFlip);
      setRiskFreeFlipToastShow(true);
      playBg(15);
      setTimeout(() => {
        getRiskFreePlays(auth?.idToken);
      }, 6900);
    }
  };

  const resetForm = () => {
    const flipId = coinFlip?.id;
    // const currentStreak = coinFlip?.won ? coinFlip?.winStreak : coinFlip?.loseStreak;
    // checkForWLKickback(flipId);

    if (isPromoFlagEnabled()) {
      // temp disabled for losers promo
      if (1 + 1 === 5) {
        setTimeout(() => {
          checkForKickback(flipId);
        }, 2000);
      }

      // FRAGMENTS/RAFFLES
      // if ([3, 5, 7, 10].includes(currentStreak)) {
      //   setTimeout(() => {
      //     checkForKickback(flipId);
      //   }, 2000);
      // }
    }

    handleRiskFreeFlipUI(flipId);
    disableLoading();
    setSuperStreak(false);
    setSkipReward(false);

    if (wallet.connected) {
      setState({
        page: 'one',
        currentWinStreak: state.currentWinStreak,
        streak: state.streak,
      });
    }
    else {
      setState({
      });
    }
  }

  const retryFlip = async (side: any, amount: any) => {
    enableLoading();
    const rewardable = await checkForReward();
    if (rewardable) return;
    const deposited = await checkForDeposit();
    if (deposited) return;
    startFlip({ side, amount });
    disableLoading();
  };

  return (
    <div className={"text-center d-flex main-header h-100vh-desktop"}>
      <ToastRugged show={ruggedToastShow} onClose={() => setRuggedToastShow(false)}></ToastRugged>
      <ToastCongrats
        showShare={shareToastShow}
        show={congratsToastShow}
        onClose={() => setCongratsToastShow(false)}
        onShareClose={() => setShareToastShow(false)}
        onOpenModal={handleShareCardModalOpen}>
      </ToastCongrats>
      <ToastKickback
        show={kickbackToastShow}
        onClose={() => setKickbackToastShow(false)}>
      </ToastKickback>
      <ToastRiskFreeFlip
        show={riskFreeFlipToastShow}
        onClose={() => setRiskFreeFlipToastShow(false)}
        riskFreeFlip={riskFreeFlip}
      />
      <CoinFlipFailedToast
        show={showCoinFlipErrorToast}
        onClose={() => handleCoinFlipToastClose()}
      />
      {
        fragmentsToastShow &&
        <ToastNewRaffles
          count={fragments}
          streak={coinFlip?.won ? coinFlip?.winStreak : coinFlip?.loseStreak}
          won={coinFlip?.won}
          show={fragmentsToastShow}
          onClose={() => setFragmentsToastShow(false)} />
      }
      {
        showShareCardModal &&
        <ShareCardModal
          playConfetti={playConfettiAnimation}
          show={showShareCardModal}
          walletId={wallet?.publicKey?.toString()}
          styleCss={style}
          amount={price}
          winStreak={state.currentWinStreak}
          onHide={() => handleShareCardModalClose()}
        />
      }
      {
        showTransactionPriorityModal &&
        <TransactionPriorityModal
          show={showTransactionPriorityModal}
          styleCss={style}
          priorityFee={priorityFee}
          onSave={(fee: any) => { setPriorityFee(fee); handleTransactionPriorityModalClose() }}
          onHide={() => handleTransactionPriorityModalClose()}
        />
      }
      {
        !state.page &&
        <CoinFlipWizardHome
          community={community}
          wallet={wallet}
          style={style}
          restricted={restricted}
          recentCoinFlips={recentCoinFlips}
          loading={loading}
          whaleMode={whaleMode}
          proMode={proMode}
          onNext={login} />
      }
      {
        state.page === "one" &&
        <CoinFlipWizardStepOne
          community={community}
          style={style}
          whaleMode={whaleMode}
          proMode={proMode}
          currentWinStreak={state.currentWinStreak}
          streak={state.streak}
          loading={loading}
          onNext={startFlip}
          side={side}
          price={price}
          riskFreePlays={riskFreePlays}
          setSide={setSide}
          setPrice={setPrice}
          openPriorityModal={handleTransactionPriorityModalOpen}
          priorityFee={priorityFee} />
      }
      {
        state.page === "two" &&
        <CoinFlipWizardStepTwo
          community={community}
          currentWinStreak={state.currentWinStreak}
          streak={state.streak}
          status={state?.status}
          side={state?.coinFlip?.side}
          amount={state?.coinFlip?.amount}
          proMode={proMode}
          style={style}
          onRetryFlip={({ side, amount }: any) => retryFlip(side, amount)}
        />
      }
      {
        state.page === "three" &&
        <CoinFlipWizardStepThree
          wallet={wallet}
          community={community}
          loading={loading}
          currentWinStreak={state.currentWinStreak}
          streak={state.streak}
          winStreakImageUrl={(community?.winStreakAssets ?? [])[state.currentWinStreak - 1]}
          amount={coinFlip?.amount}
          won={coinFlip?.won}
          side={coinFlip?.side}
          isFinalized={coinFlip?.status === CoinFlipStatus.FINALIZED}
          isSuperStreak={superStreak}
          id={coinFlip?.id}
          depositTxn={coinFlip?.depositTxn}
          skipReward={skipReward}
          createdAt={coinFlip?.createdAt}
          coinWalletId={coinFlip?.walletId}
          whaleMode={whaleMode}
          proMode={proMode}
          style={style}
          onOpenShareModal={handleShareCardModalOpen}
          onNext={() => resetForm()}
          priorityFee={priorityFee} />
      }
      {
        state.page === "continue" &&
        <CoinFlipWizardStepContinue
          community={community}
          side={side}
          amount={price}
          loading={loading}
          onDoubleOrNothing={continueFlip}
        />
      }
      {
        state.page === "continue_old" &&
        <CoinFlipWizardStepContinueOld
          community={community}
          setSide={setSide}
          side={side}
          amount={price}
          onDoubleOrNothing={continueFlipOld}
        />
      }
      {/* {
        !!state.page &&
        <SideBetsContainer shouldDisable={state.page !== "one"} />
      } */}
    </div>
  );
};

export default CoinFlipWizardContainer;
